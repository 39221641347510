.cap-info-table__column {
  color: $body-color;
  font-size: 1em;

  .row-action {
    i.fas {
      color: var(--cap-primary-color);
      cursor: pointer;
      font-size: 1em;
    }
  }

  .fas {
    color: $body-color;
  }
}

.cap-info-tablep-datatable p-datatable-thead th {
  color: $table-header-color;
  font-weight: 600;
  font-size: 0.8rem;
}

body .p-datatable .p-sortable-column:not(.p-highlight):hover {
  background: transparent;
  color: $table-header-color;
  font-weight: 600;
  text-decoration: underline;
}

.action-column {
  width: 40px;
}

.small-column {
  width: 60px;
}

.medium-column {
  width: 100px;
}

.cap-info-tablep-datatable p-datatable-tbody td {
  font-size: 0.8rem;
}

.hide-md {
  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.table-filters div.filters-label {
  color: rgba(51, 51, 51, 0.5);
  justify-content: center;
  flex-direction: column;
  font-weight: 600;
  display: flex;
}

.module-table-image {
  width: 20px;
  height: 20px;
}

.icon-only-column {
  width: 50px;
}

.cap-info-table.p-datatable .p-datatable-thead th.th_sticky {
  position: sticky;
  left: 0;
  background-color: #f9f9f9;
}

.sticky {
  position: sticky;
  left: 0;
  background-color: #fff;
}

.bordered-table .cap-info-table.p-datatable {
  table {
    border-collapse: separate;
  }

  td {
    border: solid 1px $input-border-color;
    border-right: none;
    border-left: none;
  }

  tr td:first-child {
    border-top-left-radius: 3px;
    border-left: solid 1px $input-border-color;
    border-bottom-left-radius: 3px;
  }

  tr td:last-child {
    border-top-right-radius: 3px;
    border-right: solid 1px $input-border-color;
    border-bottom-right-radius: 3px;
  }
}

cap-info-table .cap-info-table.p-datatable .p-datatable-tbody td:not(.selectable-column) {
  white-space: normal;
}

.p-datatable .p-paginator {
  padding-right: 20px;
  padding-top: 15px;
}

p-paginator .p-paginator-bottom {
  .p-paginator-left-content {
    span.text-capitalize {
      text-transform: none !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  cap-dropdown .p-dropdown {
    .p-dropdown-label {
      height: auto;
    }

    &:hover {
      height: auto !important; /* stylelint-disable-line declaration-no-important */
      .p-dropdown-label {
        height: auto !important; /* stylelint-disable-line declaration-no-important */
      }
    }
    height: auto;
  }
}

@media screen and (max-width: 767px) {
  .cap-info-table__row {
    position: relative;
  }
  .cap-info-table.p-datatable .p-datatable-tbody tr {
    box-shadow: 0 5px 13px 0 rgb(0 0 0 / 8%);
  }
  .cap-info-table__actions-column {
    height: 36px !important;
  }
  .cap-cell-tooltip-container span {
    white-space: normal;
  }
  .cap-info-table__column {
    height: auto !important;
    line-height: 19px !important;
    margin-bottom: 6px;
  }
  .cap-info-table__mobile-column {
    align-items: flex-start !important;
  }
  .cap-info-table__mobile-header {
    color: $cap-table-header-color !important;
    font-size: 14px;
    font-weight: 600;
    min-width: 40%;
    max-width: 40%;
    width: 40%;
    white-space: normal;
  }
  .cap-info-table__mobile-cell {
    font-size: 14px;
    max-width: 60%;
    flex-wrap: wrap;
    display: flex;
    white-space: normal;
  }
  [data-test='cap-info-table__column_actions'] {
    height: 0 !important;

    .cap-info-table__mobile-cell {
      position: absolute;
      top: -3px;
      right: 0;
    }
  }
}

@media screen and (min-width: 768px) {
  .cap-info-table__column-header, .cap-info-table__column {
    font-family: open sans, helvetica neue, sans-serif;
    font-size: 15px !important;
    line-height: 17px !important;
  }
  span.cap-cell-tooltip {
    white-space: normal;
  }
}
