.tox.tox-tinymce {
  min-height: 500px;
}

body .p-editor-container .ql-stroke {
  stroke: #444;
}

.readonly-editor {
  background-color: $editor-disabled-content-background;
  border: 1px solid $input-border-color;
  border-radius: 5px;
  padding: 15px;
  color: $body-color;
  line-height: 19px;
}

.editor-disabled {
  .tox.tox-tinymce {
    border-radius: 5px;

    .tox-editor-header {
      display: none;
    }

    .tox-edit-area iframe {
      background-color: $light-grey;
    }
  }
}
