@import './node_modules/@capturum/ui/styles/variables.scss';

/* General colors */
$primary: #ff9500;
$light-grey: #f9f9f9;
$black: #333;

/* Fonts */
$font-light: opensans light;
$font-regular: opensans regular;
$font-semi-bold: opensans semibold;
$font-bold: opensans bold;

/* Spacing */
$mobile-spacing: 15px;
$dashboard-page-padding: 40px;
$header-height: 163px;
$sidebar-width: 325px;
$card-gutter: 20px;

/* General */
$body-color: #5f6062;
$white: #fff;
$font-color: #5f6062;
$input-border-color: #ddd;
$placeholder-color: #b5b5b5;
$table-header-color: rgba(51, 51, 51, 0.5);
$form-value-text-color: #5f6062;
$trash-ico: #ff3b30;
$cap-toast-message-content-padding:  15px 45px;
$cap-input-height: 38px;
$cap-table-filter-icon-height: $cap-input-height;
$cap-input-text-rounded-border-color: $input-border-color;
$cap-info-table-header-color: $table-header-color;
$cap-dropdown-multiselect-trigger-border-left: none;
$cap-card-header-border-bottom: 1px solid $input-border-color;
$dialog-title-color: rgb(76, 76, 76);
$dialog-title-font-size: 26px;
$dialog-subtitle-color: rgb(95, 96, 98);
$dialog-subtitle-font-size: 14px;
$dialog-border-radius: 6px;
$dialog-box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.25);
$heading-color: #595b5c;
$card-title-color: rgb(255, 149, 0);
$card-border-color: #ddd;

/* Modules */
$module-transfer-color: rgb(93, 152, 193);
$module-manage-color: $primary;
$current-module-color: $primary;

$overlaypanel-text-color: #4f5152;
$sub-menu-disabled-color: #e1e1e1;
$title-font-family: neosansw1g-medium, sans-serif;

$cap-calendar-w-btn-hover-border: 1px solid $input-border-color;
$cap-success: #4cd964;
$cap-button-success-background: $cap-success;

$editor-disabled-content-background: $light-grey;

/* More results counter */
$more-results-counter-background: #f9f9f9;
$more-results-counter-border-color: #cecece;
$more-results-counter-dots: #dadada;

$cap-horizontal-navbar-icon-color: var(--cap-primary-color);

:root {
  --cap-primary-color-shadow: #{$primary};
  --success-color-shadow: rgba(76, 217, 100, 0.25);
  --primary-text: #{$font-color};
}
