/* Base */
@import './base/reset';
@import './base/alignment';
@import './base/typography';
@import './base/modules';
@import './base/grid';

/* Vendor */
@import './vendor/bootstrap';
@import './vendor/prime';
@import './vendor/capturum-ui';
@import './vendor/font-awesone';
@import './vendor/capturum-login';

/* Components */
@import 'components/accordions';
@import 'components/buttons';
@import 'components/input';
@import 'components/inputSwitch';
@import 'components/toast';
@import 'components/checkbox';
@import 'components/inputs';
@import 'components/dropdown';
@import 'components/table';
@import 'components/right-sidebar';
@import 'components/list-pages';
@import 'components/forms';
@import 'components/cards';
@import 'components/dialogs';
@import 'components/editor';
@import 'components/loader';
@import 'components/confirm-dialogs';
@import 'components/loader';
@import 'components/horizontal-navbar';
@import 'components/tooltips';
@import 'components/pill';
@import 'components/file-uploads';
