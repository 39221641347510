/* You can add global styles to this file, and also import other style files */
@import './styles/main.scss';
@import '@angular/material/prebuilt-themes/indigo-pink.css';

:root {
    --text-color-4: #fff;
}

cap-row-action p.title,
cap-toast .toast-detail,
.page-header .header-title,
.header .card__title {
    display: inline-block;

    &:first-letter {
        text-transform: capitalize;
    }
}

cap-input .p-inputgroup input.p-inputtext:disabled,
cap-input .p-inputgroup input.p-inputtext:hover:disabled {
    border: none;
}

* {
    outline: none;
}


.button__disabled {
    opacity: 0.75;
    pointer-events: none;
}

.cdk-overlay-container {
    z-index: 9999;
}

// Override blueprint
.header {
    box-shadow: none;
}
