.pill {
  margin-left: 6px;
  border-radius: 17px;
  font-size: 0.8em;
  background: $light-grey;
  padding: 5px 10px;
  text-align: center;
  font-weight: bold;
  display: inline-block;
  border: 1px solid $input-border-color;

  &.primary {
    background: var(--cap-primary-color);
    color: $white;
  }
}

.tab-title {
  margin-top: 4px;
}
