@import 'abstracts';

$accordion-padding: 20px 0;
$accordion-margin: 0 30px;

.accordion-item {
  border: 1px solid $input-border-color;
  border-radius: 5px;
  margin-bottom: 20px;
}

.p-accordion .p-accordion-header-text {
  display: block;
  width: calc(100% - 30px);
  color: $body-color;
  font-size: 1.3em;
  font-weight: 600;
  transition: all 0.3s;
}

.p-accordion .p-accordion-header a {
  position: relative;
  margin: $accordion-margin;
  padding: $accordion-padding;
  outline: none;

  &:hover {
    text-decoration: none;

    .p-accordion-header-text {
      color: $primary;
    }
  }
}

body .p-accordion .p-accordion-header a .p-accordion-toggle-icon {
  position: absolute;
  right: 0;

  @include media-breakpoint-down(sm) {
    float: none;
    transform: none;
  }
}

.p-accordion .p-accordion-content {
  border-top: 1px solid $input-border-color;
  margin: $accordion-margin;
  padding: $accordion-padding;
}

cap-accordion-card {
  &.with-border .accordion-card {
    border: 1px solid $input-border-color;
  }

  .accordion-card {
    border-radius: 5px;
  }
}
