.cap-rounded {
  height: 30px;
  border-color: $input-border-color;
}

.disabled-input {
  background: transparent;
  border-color: transparent;
  cursor: text;
  pointer-events: none;
}
