@import '~primeng/resources/primeng.min.css';
@import 'abstracts';

body .p-corner-all {
  background: $white;
}
body .p-sidebar {
  max-width: 486px;
}
body .p-dropdown .p-dropdown-label, body .em-input {
  font-size: 13px;
}
body .p-dropdown-panel .p-dropdown-item {
  padding: 5px;
  font-size: 13px;
}
body .p-dropdown {
  border: 1px solid rgb(221, 221, 221);
}
body .p-dropdown .p-dropdown-trigger {
  right: 5px;
}

body .ez-input {
  font-size: 16px !important;/* stylelint-disable-line */
}
