cap-validator {
  display: none;
}

.content-right cap-validator, .visibile-validators cap-input cap-validator {
  display: block;
}

.form-section-header {
  color: var(--cap-primary-color);
  font-size: 1.2em;
  margin: 15px 0;
  font-weight: 600;
  padding-left: 8px;

  i {
    margin-right: 10px;
  }
}

@include media-breakpoint-up(lg) {
  .row .col-border-right:not(:last-of-type) {
    border-right: 1px solid $input-border-color;
  }
}

cap-input input {
  width: 100%;
}

em-wrapper-form-field label {
  white-space: nowrap;
}

formly-form {
  cap-validator {
    display: none;
  }

  formly-validation-message {
    font-size: 0.9em;
  }
}

.form-card__form--disabled {
  .address-field formly-field[class^='col-'] {
    flex-basis: unset;
    max-width: unset;
  }
}

.disabled-form formly-form .form-group > label {
  margin-bottom: 0;
}
