@import 'abstracts';

.row.bottom-gutters-md {
  @include media-breakpoint-down(md) {
    @include setBottomGutters(15px);
  }
}

.row.bottom-gutters-lg {
  @include media-breakpoint-down(lg) {
    @include setBottomGutters(15px);
  }
}
