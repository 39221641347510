.cap-rounded.cap-multi-select.p-multiselect,
.cap-rounded.cap-dropdown.p-dropdown,
.cap-rounded.cap-dropdown.p-dropdown:not(.disabled).p-focus,
.cap-rounded.cap-multi-select.p-multiselect:not(.p-disabled):hover,
.cap-rounded.cap-dropdown.p-dropdown:not(.p-disabled):hover {
  border-color: $input-border-color;
}

.p-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon,
.p-multiselect .p-multiselect-trigger .p-multiselect-trigger-icon {
  font-size: 0.9em;
}

.p-multiselect-close .pi {
  font-size: 0.9em;
}

body p-multiSelect:not(.p-inputwrapper-filled) .p-multiselect .p-multiselect-label {
  color: $placeholder-color;
}

.cap-dropdown.p-dropdown {
  &.p-disabled {
    border: none;
    opacity: 1;
    height: unset;

    .p-inputtext {
      border: none;
    }

    .p-dropdown-label {
      color: $form-value-text-color;
    }

    .p-dropdown-trigger {
      display: none;
    }
  }
  .p-dropdown-label {
    display: flex;
    align-items: flex-start;
  }
}

li.p-multiselect-empty-message {
  padding: 0.4rem 0.9rem;
}

body .p-dropdown-label.p-inputtext {
  border: none;
}

.p-multiselect-item-group {
  padding: 15px;
  font-weight: 600;
}
