@import 'abstracts';

.cap-button.primary,
.cap-button.secondary,
.cap-button.error,
.cap-button.success {
  border-width: 1px;
  padding: 10px 15px;
}

button:focus {
  outline: none;
}

button:disabled,
button[disabled],
.btn-disabled {
  pointer-events: none;
}

cap-button.w-100 {
  p-button {
    width: 100%;
  }
}

.p-button-icon-left {
  left: 1em;
  padding-right: 0;
  margin-right: 1em;
}

.p-button.cap-button {
  border-radius: 3px;

  &.p-button-text-icon-left {
    padding-right: 15px;

    .p-button-icon-left {
      left: 0.7em;
    }
  }
}

.cap-button.cap-rounded {
  border-radius: 16px;
  padding: 2px 10px;
  font-size: 1rem;

  .p-button-text {
    padding: 0 1em;
  }
}

.btn-white-orange {
  background: rgb(255, 255, 255);
  color: rgb(255, 149, 0);
  border: 1px solid rgb(255, 149, 0);
}

.btn-width-100 {
  cap-button {
    width: 100%;
    display: block;
  }

  p-button {
    width: 100%;
    display: block;
  }
}

body cap-button .p-button,
body cap-button .p-button.p-button-label-icon-left,
body cap-button .p-button.p-button-label-icon-right {
  .p-button-label {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.app-export-icon-spin {
  &__animation {
    pointer-events: none;

    .p-button-icon {
      animation: spin 1s linear infinite;
    }
    i {
      animation: spin 1s linear infinite;
    }

    &:hover {
      text-decoration: none !important;

      .p-button-label {
        text-decoration: underline !important;
      }
    }

    button {
      &:hover {
        text-decoration: none !important;

        .p-button-label {
          text-decoration: underline !important;
        }
      }
    }

    &.primary:not(:disabled) {
      background-color: $cap-input-border-color;
      border-color: $cap-input-border-color;
      box-shadow: 0 2px 4px 0 #ddd;
    }
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
