$breakpoints: (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1440px);

.content-right .login-form {
  body cap-login & {
    min-height: 300px;
  }
  cap-input-switch {
    display: none;
  }

  .form-element {
    margin-bottom: 15px;
  }

  .form-element label {
    display: none;
  }

  .title {
    font-size: 26px;
    font-weight: 500;
    font-family: NeoSansW1G-Medium, sans-serif;
    margin-bottom: 30px;
  }

  .login-actions span.login-separator {
    color: rgb(225, 225, 225);
    font-size: 14px;
    font-weight: 600;
  }
}

body cap-login {
  .login-wrapper {
    @include media-breakpoint-up(md, $breakpoints) {
      transform: translate3d(0, -50%, 0);
    }
    cap-button {
      .primary:not(:disabled) {
        background-color: #ff9500;
        &:hover {
          background-color: #ff9500;
        }
      }
      .secondary:not(:disabled) {
        color: #ff9500;
        border: 2px solid #ff9500;
        &:hover {
          border: 2px solid #ff9500;
          color: #ff9500;
        }
      }
    }
  }

  .content-wrapper {
    &.row {
      max-width: 540px;
      border-radius: 15px;
      @include media-breakpoint-up(md, $breakpoints) {
        margin-top: 0;
      }

      @include media-breakpoint-up(lg, $breakpoints) {
        transform: translate3d(-10%, 0, 0);
      }

      @include media-breakpoint-up(xxl, $breakpoints) {
        transform: translate3d(-75%, 0, 0);
      }
    }

    .content-left {
      display: none;
    }

    .content-right {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
