@import 'abstracts/project_variables';

body .p-dialog {
  min-height: 150px;
  padding: 50px;

  .p-dialog-header {
    justify-content: center;

    .p-dialog-title {
      font-size: 26px;
      font-weight: bold;
      line-height: 1.5;
    }

    .p-dialog-header-icons {
      position: absolute;
      right: 15px;
      top: 15px;
    }
  }

  .p-confirmdialog-message {
    color: $body-color;
  }

  .p-dialog-content {
    justify-content: center;
    margin-bottom: 25px;

    .p-confirm-dialog-message {
      color: $dialog-subtitle-color;
      font-size: 14px;
      height: 19px;
    }
  }

  .p-dialog-footer {
    padding: 1em;
    border: none;
    position: unset;
    text-align: center;

    cap-button {
      padding: 5px;

      button {
        height: 40px;
        min-width: 174px;

        &.bg-danger {
          background-color: $trash-ico !important; /* stylelint-disable-line */
        }
      }
    }
  }

  .p-dialog-titlebar {
    text-align: center;

    .p-dialog-title {
      font-size: 1.5em;
    }
  }
}
.responsive-modal {
  z-index: 999;

  .p-dialog {
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}
