body .app-header .app-header__modules cap-horizontal-navbar {
  nav.cap-horizontal-navbar div.navbar-collapse {
    @include media-breakpoint-up(lg) {
      overflow-x: scroll;
      justify-content: unset;
    }
  }

  .nav-item {
    white-space: nowrap;
    @include media-breakpoint-down(lg) {
        border-right: 0.5px solid #dee2e6;
    }

  }

  .primary-menu-item {
    i {
      color: $primary;
    }
  }

  .navbar-collapse .nav-item.active .nav-item-div span.nav-item-title {
    color: var(--cap-primary-color);
  }
}
