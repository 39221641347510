@import 'abstracts';

cap-card .card {
  margin-bottom: $card-gutter;
  box-shadow: none !important; /* stylelint-disable-line declaration-no-important */
  border: none;
  background: $white;
  padding: 20px;
  color: $body-color;

  .card__header {
    padding-bottom: 18px !important; /* stylelint-disable-line declaration-no-important */

    span {
      i:not(.fa-stack-1x):not(.fa-stack-2x) {
        color: var(--cap-primary-color);
        padding-right: 10px;
        font-size: 20px;
      }

      .card__title {
        font-size: 1.3em;
        font-weight: 500;
        font-family: NeoSansW1G-Medium, sans-serif;
      }
    }
  }

  .content {
    padding-bottom: 5px;

    .title {
      padding-bottom: 10px;
    }
  }
}
