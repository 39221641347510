@import 'abstracts';

.loader {
  color: var(--primary-color, $primary);

  .label {
    color: $body-color;
    font-size: 14px;
  }
}

.cap-loader {
  z-index: 999;
}
