@import 'abstracts/project_variables';

:root {
  --current-module-color: #{$primary};
}

.module- {
  &manage {
    --current-module-color: #{$module-manage-color};
  }

  &transfer {
    --current-module-color: #{$module-transfer-color};
  }
}
