body .p-chkbox .p-chkbox-box {
  border: 1px solid #a6a6a6;
  background-color: #fff;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 3px;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.success.p-checkbox {
  .p-checkbox-box.p-highlight,
  .p-checkbox-box.p-highlight.p-focus:not(.p-disabled),
  .p-checkbox-box.p-highlight.p-focus:not(.p-disabled):hover,
  .p-checkbox-box.p-highlight:not(.p-disabled):hover {
    border-color: $cap-success;

    .p-checkbox-icon:before {
      color: $cap-success;
    }
  }
}
