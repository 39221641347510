@import 'abstracts/project_variables';

body {
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $placeholder-color !important; /* stylelint-disable-line declaration-no-important */
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $placeholder-color !important; /* stylelint-disable-line declaration-no-important */
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
        color: $placeholder-color !important; /* stylelint-disable-line declaration-no-important */
    }
}

html, body {
    font-family: Open Sans, sans-serif;
    font-size: 14px;
}

@font-face {
    font-family: NeoSansW1G-Medium;
    src: url('../../assets/fonts/neo-sans-w1g-medium.otf');
    font-weight: 500;
}

@font-face {
    font-family: NeoSansW1G-Regular;
    src: url('../../assets/fonts/neo-sans-w1g-regular.otf');
    font-weight: 500;
}

.primary-text {
    color: $primary;
}

a,
a:not([href]):not([class]),
a:not([href]):not([class]):hover,
a:hover {
    text-decoration: underline;
    cursor: pointer;
    color: $primary;
}

h5 {
    color: $heading-color;
    font-size: 1.2em;
    font-weight: 500;
    font-family: NeoSansW1G-Medium, sans-serif;
}

.text-primary {
    color: var(--primary-color, $primary) !important; /* stylelint-disable-line declaration-no-important */
}
