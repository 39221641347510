.sidebar-header {
  background: linear-gradient(-225deg, rgb(255, 149, 0) 0%, rgb(255, 172, 55) 100%);
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 30px;
  color: white;

  span {
    font-size: 1.5em;
  }

  h5 {
    margin-bottom: 0;
  }

  i {
    cursor: pointer;
  }
}

.sidebar-body {
  color: rgb(95, 96, 98);

  .body-part {
    margin-bottom: 30px;

    h5 {
      font-size: 1.2em;
      font-weight: 600;
      margin-bottom: 10px;
    }
  }

  .qr-code {
    text-align: center;

    img {
      width: 200px;
      margin: 20px 0;
    }

    span {
      font-style: italic;
    }
  }

  .input-codes {
    max-width: 280px;
    margin: auto;
    @media screen and (max-width: 400px) {
      max-width: 255px;
    }
  }
}

.sidebar-footer {
  height: 100px;
  border-top: 1px solid #dadada;
  width: 90%;
  margin: auto;
  padding: 30px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sidebar-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  p {
    font-size: 1em;
  }

  .small-description {
    font-size: 0.9em;
    color: $body-color;
    font-style: italic;
  }
}

.done-text {
  color: #35c44d;
  font-size: 1em;
  font-weight: bold;
}

.border-yellow-dashed {
  width: 4px;
  height: auto;
  background-repeat: repeat-y;
  background-size: 2px 15px;
  background-image: linear-gradient(to bottom, #ff9500 0%, #ff9500 50%, transparent 50%);
}

.shadow-blocks {
  width: calc(100% - 4px);
  padding-left: 10px;

  .block-shadow {
    font-size: 1em;
    padding: 15px;
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }

  h6 {
    font-weight: 600;
    margin-bottom: 5px;
  }

  .bottom-date {
    color: rgba(51, 51, 51, 0.5);
    font-size: 13px;
    margin-bottom: 10px;
  }

  .c-gray {
    color: #818284;
  }

  .line-through {
    color: #c6c6c6;
    text-decoration: line-through;
  }
}
