@import './node_modules/@capturum/ui/styles/intergrip.theme';
@import './node_modules/@capturum/ui/styles/styles';
@import './src/styles/abstracts';

body .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider,
body .p-inputswitch.p-inputswitch-checked:not(.p-state-disabled):hover .p-inputswitch-slider,
body .p-inputswitch.p-inputswitch-checked.p-inputswitch-focus .p-inputswitch-slider {
  background: $green;
}

:root {
  --error-color: #{$cap-danger};
  --cap-primary-color: #{$primary};
  --primary-font: open sans, sans-serif;
}

.cap-dropdown.p-dropdown .p-dropdown-trigger, .cap-dropdown.p-dropdown.p-dropdown:not(.p-state-disabled):hover .p-dropdown-trigger {
  border-left: none;
}

body .nav-bar-gradient {
  display: none;
}

body .p-overlaypanel .p-overlaypanel-content {
  padding: 0;
}

.cap-dropdown.p-dropdown .p-dropdown-label,
.cap-dropdown.p-dropdown .p-multiselect-label,
.cap-dropdown.p-dropdown.p-dropdown:not(.p-disabled):hover .p-dropdown-label,
.cap-dropdown.p-dropdown.p-dropdown:not(.p-disabled):hover .p-multiselect-label,
.cap-dropdown.p-dropdown.p-dropdown:not(.p-disabled).p-focus .p-dropdown-label,
.cap-dropdown.p-dropdown.p-dropdown:not(.p-disabled).p-focus .p-multiselect-label,
.cap-dropdown.p-dropdown.p-multiselect:not(.p-disabled):hover .p-dropdown-label,
.cap-dropdown.p-dropdown.p-multiselect:not(.p-disabled):hover .p-multiselect-label,
.cap-dropdown.p-dropdown.p-multiselect:not(.p-disabled).p-focus .p-dropdown-label,
.cap-dropdown.p-dropdown.p-multiselect:not(.p-disabled).p-focus .p-multiselect-label,
.cap-multi-select.p-multiselect .p-dropdown-label,
.cap-multi-select.p-multiselect .p-multiselect-label,
.cap-multi-select.p-multiselect.p-dropdown:not(.p-disabled):hover .p-dropdown-label,
.cap-multi-select.p-multiselect.p-dropdown:not(.p-disabled):hover .p-multiselect-label,
.cap-multi-select.p-multiselect.p-dropdown:not(.p-disabled).p-focus .p-dropdown-label,
.cap-multi-select.p-multiselect.p-dropdown:not(.p-disabled).p-focus .p-multiselect-label,
.cap-multi-select.p-multiselect.p-multiselect:not(.p-disabled):hover .p-dropdown-label,
.cap-multi-select.p-multiselect.p-multiselect:not(.p-disabled):hover .p-multiselect-label,
.cap-multi-select.p-multiselect.p-multiselect:not(.p-disabled).p-focus .p-dropdown-label,
.cap-multi-select.p-multiselect.p-multiselect:not(.p-disabled).p-focus .p-multiselect-label {
  padding: 0.429em;
}
