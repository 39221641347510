@import 'abstracts';

body .p-dialog {
  border-radius: $dialog-border-radius !important; /* stylelint-disable-line */
  box-shadow: $dialog-box-shadow !important; /* stylelint-disable-line */
  background: #fff;

  .p-dialog-titlebar {
    border: none !important; /* stylelint-disable-line */
    background-color: $white;
    border-radius: $dialog-border-radius;
  }

  .p-dialog-content {
    border: none !important; /* stylelint-disable-line */
    border-radius: $dialog-border-radius;
  }
}
.overflow-x-hidden {
  .p-dialog-content {
    overflow-x: hidden;
  }
}
