cap-file-upload {
  label.prime-file-upload {
    height: unset;
    display: block;
    border: 2px dashed #d0d0d0;
    box-shadow: none;
    border-radius: 7px;
    margin: 0;
    text-align: left !important; /* stylelint-disable-line declaration-no-important */

    i {
      display: inline;
      font-size: 1.4em;
      color: $font-color;
      opacity: 1;
      margin-right: 10px;
    }
  }

  .file-upload-drag-label {
    color: #d0d0d0;
  }
}
